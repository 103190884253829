<template>
    <div class="wrap" style="overflow: auto">
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <AddSpace
            v-if="ShowAddSpace"
            :spaceType="spaceType"
            :spaceId="spaceId"
            :isRegistCard="isRegistCard"
            @closeAddSpace="ShowAddSpace = false"
        />
        <header class="header mo_header">
            <div class="header_box">
                <div class="logo">
                    <a @click="linkPlayer()">
                        <img src="/media/images/icon_back_24.svg" />
                        <h2>이용권 소개</h2>
                    </a>
                </div>
                <div class="right_menu">
                    <div class="alarm_box" @click="mobileAlarm">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M2.00024 18.666H20.3786"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <ellipse
                                v-if="$store.state.noticeCount !== 0"
                                cx="17.6756"
                                cy="6.44444"
                                rx="4.32431"
                                ry="4.44444"
                                fill="#FF1150"
                            />
                        </svg>
                    </div>
                    <div class="toggle" @click="showAside">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2231_20993)">
                                <path
                                    d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2231_20993">
                                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </header>
        <section class="main product">
            <div class="main_box">
                <h1>이용권 소개</h1>
                <h2>합리적 가격으로 ‘공간별’, ‘매순간’ 가장 잘 어울리는 음악을 재생해보세요.</h2>
                <div class="two_box">
                    <div class="shop_regi">
                        <ul>
                            <h4>매장용</h4>
                            <li>
                                <p>
                                    매장 분위기에 따라서 음악이 자동으로 나와요.<br />자동 on/off 기능으로 설정한 시간에 자동으로 매장에서
                                    노래를 재생해요.
                                </p>
                            </li>
                        </ul>
                        <div class="payment">
                            <p>월</p>
                            <!--<em>12,000원</em>-->
                            <p v-if="isRegistCard"><span>9,000</span>원</p>
                            <p v-else><span>0</span>원</p>
                        </div>
                        <button v-if="isRegistCard" id="payment_btn_shop" type="button" @click="insertSpace('Store')">
                            스페이스 등록
                        </button>
                        <button v-else id="payment_btn_shop" type="button" @click="insertSpace('Store')">체험용 시작하기</button>
                        <!-- <p>{{ month }}월 한정!<span></span></p> -->
                    </div>
                    <div class="car_regi">
                        <ul>
                            <h4>차량용</h4>
                            <!-- <h4>차량용 <span>Beta</span></h4> -->
                            <li>
                                <p>
                                    클럽, 카페 등 선호하는 차량 분위기에 맞는 음악을 재생해요.<br />여행할 때와 출퇴근 할 때 듣는 노래는
                                    다르죠. 이용목적에 어울리는 음악을 틀어줘요.
                                </p>
                            </li>
                        </ul>
                        <div class="payment">
                            <p>월</p>
                            <!--<em>3,000원</em>-->
                            <p v-if="isRegistCard"><span>2,000</span>원</p>
                            <!--<em>3,000원</em>-->
                            <p v-else><span>0</span>원</p>
                        </div>
                        <button v-if="isRegistCard" id="payment_btn_car" type="button" @click="insertSpace('Car')">
                            스페이스 등록
                        </button>
                        <button v-else id="payment_btn_car" type="button" @click="insertSpace('Car')">체험용 시작하기</button>
                        <!-- <p>{{ month }}월 한정!<span></span></p> -->
                    </div>
                </div>
                <div class="regi_bottom">
                    <h5>유의사항</h5>
                    <ul>
                        <li>
                            <span></span>
                            <p>PC, 안드로이드폰, 아이폰, 태블릿 등에서 이용 가능합니다.</p>
                        </li>
                        <!-- <li><span></span><p>미성년자가 구매하는 경우, 법정대리인이 동의하지 않으면 미성년자 또는 법정대리인이 구매를 취소할 수 있습니다.</p></li> -->
                        <li>
                            <span></span>
                            <p>
                                결제 수단의 정지/한도초과/정보변경 등의 사유로 결제상태가 변경될 경우 결제 및 서비스 제공이 중단되며,
                                정상으로 전환되거나 신규 결제수단 추가하시면 다시 결제 및 서비스가 시작됩니다.
                            </p>
                        </li>
                        <li>
                            <span></span>
                            <p>
                                매월 결제일 기준 7일 이내 이용내역이 없는 경우, 마이스페이스 메뉴에서 구독 해지 신청 가능하며 전액
                                환불됩니다. (3영업일 이내)
                            </p>
                        </li>
                        <li>
                            <span></span>
                            <p>계정별 중복 로그인은 가능하나, 하나의 스페이스는 동시에 하나의 기기에서만 재생 가능합니다.</p>
                        </li>
                        <li>
                            <span></span>
                            <p>스페이스별로 과금이 부과됩니다.</p>
                        </li>
                        <li>
                            <span></span>
                            <p>상기 금액은 VAT 별도 금액입니다.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import Aside from '@/layout/aside/Aside.vue';
import AddSpace from '@/components/modal/Main/AddSpace2.vue';
import VueCookies from 'vue-cookies';
import router from '@/router';
import axios from '@/service/axios';

export default defineComponent({
  name: 'product',
  data () {
    return {
      asideStatus: false,
      isRegistCard: true,
      ShowAddSpace: false,
      spaceId: '',
      spaceType: '',
      month: ''
    };
  },
  components: {
    Aside,
    AddSpace
  },
  mounted () {
    setTimeout(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('aplayzaos') !== -1) {
        document.getElementsByClassName('header mo_header')[0].style.padding = '13px 0 0';
      } else if (userAgent.indexOf('aplayznativeaos') !== -1) {
        document.getElementsByClassName('header mo_header')[0].style.padding = '13px 0 0';
      }
    }, 1000);
  },
  methods: {
    getMonth () {
      const today = new Date();
      this.month = today.getMonth() + 1;
    },
    checkRegistCard () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    linkPlayer () {
      var spaceId = VueCookies.get('spaceId');
      if (spaceId !== null) {
        router.push({ name: 'Player' });
      } else {
        router.push({ name: 'Main' });
      }
    },
    clickPre () {
      history.back();
    },
    showAside () {
      this.asideStatus = true;
    },
    mobileAlarm () {
      this.$router.push({ name: 'Notice0' });
    },
    linkNext (spaceType) {
      this.isRegistCard ? this.$store.commit('setTrialStatus', false) : this.$store.commit('setTrialStatus', true);
      spaceType === 'Store'
        ? router.push({ name: 'SpaceInsertStore', params: { spaceId: null } })
        : router.push({ name: 'SpaceInsertCar', params: { spaceId: null } });
    },
    insertSpace (spaceType) {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSpace', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            let sCount = -1;
            if (result.length > 0) sCount = result.filter(e => e === 'S').length;
            if (sCount === result.length) {
              // 보유 스페이스가 전부 S인 경우
              axios
                .post('/api/profile/getRecentSpaceInfoByUserByType', { userId, spaceType }, { headers })
                .then(res2 => {
                  // 타입별 최근 등록 스페이스ID 가져오기
                  if (res2.data.resultCd === '0000') {
                    this.spaceId = res2.data.result;
                    this.spaceType = spaceType;
                    this.ShowAddSpace = true;
                  }
                })
                .catch(err2 => {
                  // 등록된 스페이스가 없으면 등록페이지 이동
                  const result2 = err2.response.data;
                  if (result2.resultCd === '400' && result2.resultMsg === '조회된 데이터가 없습니다.') {
                    this.linkNext(spaceType);
                  }
                });
            } else {
              this.linkNext(spaceType);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>

<style scoped>
/*produdct_0*/
.main.product {
  width: 100%;
  padding-top: 132px;
}

.product .main_box {
  width: 1200px;
  margin: 0 auto;
  max-width: 1200px;
}

.product .main_box h1 {
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 42px;
  letter-spacing: -0.5px;
}

.product .main_box h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 24px;
}

.product .two_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
  border-bottom: 0px solid rgba(255, 255, 255, 0.08);
}

.product .shop_regi,
.product .car_regi {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #2a2a2a;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
}

.product .two_box h4 {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.product .two_box h4 span {
  font-family: LaTo;
  font-size: 16px;
  font-weight: 600;
  color: #ff2d55;
  padding-left: 12px;
}

.product .two_box ul {
  width: 125%;
  height: 100%;
  padding: 10px 0;
  margin-bottom: 0;
}

.product .two_box ul li {
  margin-bottom: 8px;
}

.product .two_box ul li img {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
}

.product .two_box ul li p {
  display: inline-block;
  color: #e0e0e0;
  font-size: 16px;
  width: 100%;
  opacity: 0.87;
}

.product .payment {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  border-radius: 8px;
}

.product .payment p {
  color: rgba(255, 255, 255, 0.64);
  font-size: 16px;
  padding-left: 12px;
  margin-bottom: 0;
}

.product .payment p:nth-child(2) {
  color: #fff;
  font-size: 20px;
}

.product .payment p:nth-child(2) em {
  margin-right: 12px;
  font-style: normal;
  color: #666;
  font-size: 18px;
  font-weight: 400;
  text-decoration: line-through;
}

.product .payment p:nth-child(2) span {
  font-size: 24px;
  font-weight: 500;
}

.product .two_box button {
  width: 340px;
  height: 46px;
  background: #ff2d55;
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s;
  padding: 0;
}

.product .shop_regi:hover,
.product .car_regi:hover {
  box-shadow: 0px 26px 40px rgba(0, 0, 0, 0.8);
}

.product .shop_regi > p {
  position: relative;
  position: absolute;
  top: 16%;
  left: 50%;
  margin-left: 42%;
  transform: translateX(-50%);
  padding: 4px 12px;
  border-radius: 50px;
  background: #151515;
  border: 1px solid #fd4e6d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ff2d55;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  animation: bounce2 2s ease infinite;
}

.product .car_regi > p {
  position: relative;
  position: absolute;
  top: 16%;
  left: 50%;
  margin-left: 42%;
  transform: translateX(-50%);
  padding: 4px 12px;
  border-radius: 50px;
  background: #151515;
  border: 1px solid #fd4e6d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #ff2d55;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, 0);
  }

  40% {
    transform: translate(-50%, -30px);
  }

  60% {
    transform: translate(-50%, -15px);
  }
}

.product .shop_regi > p span {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 9px;
  height: 9px;
  border-radius: 2px;
  background: #151515;
  border: 1px solid #fd4e6d;
}

.product .shop_regi > p span:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -2px;
  display: block;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #151515;
  transform: rotate(-45deg) translate(-3px, -4.5px);
}

.product .car_regi > p span {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 9px;
  height: 9px;
  border-radius: 2px;
  background: #151515;
  border: 1px solid #fd4e6d;
}

.product .car_regi > p span:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -2px;
  display: block;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #151515;
  transform: rotate(-45deg) translate(-3px, -4.5px);
}

.product .car_regi > img {
  position: absolute;
  top: -25px;
  left: -23px;
}

.product .regi_bottom {
  padding: 32px 0 50px;
}

.product .regi_bottom h5 {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.product .regi_bottom ul {
  margin-top: 8px;
  padding-left: 0;
}

.product .regi_bottom ul li {
  margin-bottom: 4px;
  display: flex;
  align-items: flex-start;
}

.product .regi_bottom ul li span {
  display: block;
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.64);
  border-radius: 50%;
  margin: 8px 10px 0 0;
}

.product .regi_bottom ul li p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  margin-bottom: 0;
}

@media all and (max-width: 1200px) {
  .product .main_box {
    width: 90%;
  }

  .product .two_box ul {
    height: auto;
  }
}

@media all and (max-width: 1023px) {
  /*produdct_0*/
  .main.product {
    padding-top: 132px;
  }

  .product .main_box {
    margin: 0 auto;
  }

  .product .two_box {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }

  .product .two_box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .product .shop_regi,
  .product .car_regi {
    width: 100%;
    padding: 20px 25px;
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
  }

  .product .shop_regi {
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
  }

  .product .two_box h4 {
    font-size: 18px;
  }

  .product .two_box ul {
    width: 100%;
  }

  .product .two_box ul li p {
    font-size: 14px;
    width: 100%;
  }

  .product .payment {
    margin: 12px 0;
    justify-content: space-between;
    padding: 0;
  }

  .product .payment p {
    font-size: 14px;
    padding: 0;
  }

  .product .payment p:nth-child(2) {
    font-size: 18px;
  }

  .product .payment p:nth-child(2) span {
    font-size: 20px;
  }

  .product .two_box button {
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
  }

  .product .regi_bottom h5 {
    font-size: 12px;
    text-decoration: none;
  }

  .product .regi_bottom ul {
    padding-left: 0;
  }

  .product .regi_bottom ul li p {
    font-size: 12px;
    width: 95%;
    margin-bottom: 0;
  }

  .product .shop_regi > p {
    position: relative;
    position: absolute;
    top: 67%;
    left: 50%;
    margin-left: 0%;
    transform: translateX(-50%);
    padding: 4px 12px;
    border-radius: 50px;
    background: #151515;
    border: 1px solid #fd4e6d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ff2d55;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    animation: bounce2 2s ease infinite;
  }

  .product .car_regi > p {
    position: relative;
    position: absolute;
    top: 69%;
    left: 50%;
    margin-left: 0%;
    transform: translateX(-50%);
    padding: 4px 12px;
    border-radius: 50px;
    background: #151515;
    border: 1px solid #fd4e6d;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ff2d55;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    animation: bounce2 2s ease infinite;
  }
}

@media all and (max-width: 768px) {
  /*produdct_0*/
  .main.product {
    width: 100%;
    padding: 80px 0 0;
  }

  .product .main_box {
    width: 90%;
    margin: 0 auto;
  }

  .product .main_box h1 {
    display: none;
    font-size: 20px;
    margin-bottom: 24px;
  }

  .product .main_box h2 {
    display: none;
  }

  .product .two_box {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }

  .product .shop_regi,
  .product .car_regi {
    width: 100%;
    padding: 20px 25px;
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
  }

  .product .shop_regi {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }

  .product .two_box h4 {
    font-size: 18px;
  }

  .product .two_box ul {
    width: 100%;
  }

  .product .two_box ul li p {
    font-size: 14px;
    width: 100%;
  }

  .product .payment {
    margin: 12px 0;
    justify-content: space-between;
    padding: 0;
  }

  .product .payment p {
    font-size: 14px;
    padding: 0;
  }

  .product .payment p:nth-child(2) {
    font-size: 18px;
  }

  .product .payment p:nth-child(2) span {
    font-size: 20px;
  }

  .product .two_box button {
    width: 100%;
    padding: 12px 0;
    font-size: 16px;
  }

  .product .regi_bottom h5 {
    font-size: 12px;
    text-decoration: none;
  }

  .product .regi_bottom ul li p {
    font-size: 12px;
    width: 100%;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
